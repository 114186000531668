<script setup lang="ts">
import { computed, inject, onMounted } from "vue";

import { TAB_STATE } from "./keys";

const props = defineProps<{ title: string }>();

const tabState = inject(TAB_STATE);

onMounted(() => {
  tabState?.titles.push(props.title);
});

const isActive = computed(
  () => tabState?.titles[tabState?.activeIndex] === props.title,
);
</script>

<template>
  <div v-if="isActive" class="tab">
    <slot></slot>
  </div>
</template>
