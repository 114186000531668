<script setup lang="ts">
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { Group } from "@/model/session";

const props = defineProps<{
  type: "art" | "team";
  title: string;
  groups: Group[];
  value: Group;
}>();
const emit = defineEmits<{ change: [group: Group] }>();

const tooltipKeys: { [key in typeof props.type]: string } = {
  art: /*$t*/ "general.arts",
  team: /*$t*/ "general.teams",
};
</script>

<template>
  <DropdownMenu
    :style="{ maxHeight: '300px' }"
    :offset="{ top: 12 }"
    width="sm"
    :is-aria-menu="true"
  >
    <template #trigger="{ isOpen }">
      <BaseTooltip class="tooltip">
        <template #content>
          {{ $t(tooltipKeys[props.type]) }}
        </template>
        <BaseButton
          variant="ghost"
          color="grey"
          class="team-button"
          data-dropdown-trigger
          :icon-before="type"
          :activated="isOpen"
          :aria-expanded="isOpen"
          aria-controls="group-dropdown-menu"
          aria-haspopup="menu"
        >
          <span class="text">{{ value.name }}</span>
        </BaseButton>
      </BaseTooltip>
    </template>
    <SelectableList
      id="group-dropdown-menu"
      v-keyboard-nav
      role="dialog"
      :header="title"
      :values="groups"
      :model-value="value"
      @update:model-value="emit('change', $event)"
    >
      <template #default="{ value }">
        {{ value.name }}
      </template>
    </SelectableList>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.team-button {
  font-weight: font.$weight_normal;
}

.tooltip {
  display: flex;
}
</style>
