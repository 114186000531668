<script setup lang="ts">
import { ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

const buttonRef = ref<HTMLElement | null>(null);
const focus = () => buttonRef.value?.focus();
defineExpose({ focus });
</script>

<template>
  <BaseButton
    ref="buttonRef"
    class="objective-btn"
    variant="ghost"
    color="primary"
    icon-before="plus"
    :aria-label="$t('label.objective.add')"
  >
    {{ $t("objectives.add") }}
  </BaseButton>
</template>
