<script setup lang="ts">
import { computed } from "vue";

import { navigationActions } from "@/action/navigationActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { boardTypeName } from "@/model/baseTypeI18n";
import { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { teamChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import GroupDropdown from "./GroupDropdown.vue";

const boardType = computed(() => useBoardStore().currentBoard().type);
const boardName = computed(() => boardTypeName(boardType.value));

const flexBoard = computed(() => {
  const board = useBoardStore().currentBoard();
  return board.type === "flex" ? board : undefined;
});

function selectFlex() {
  navigationActions.goToCanvas("mainMenu");
}

const teams = computed(() => useTeamStore().teamsInCurrentArt);
const currentTeam = computed(() => useTeamStore().currentTeam);

function selectTeam(team: Team) {
  trackEvent(teamChanged(boardType.value, "topbar-menu"));
  navigationActions.changeTeam("mainMenu", team);
}

const arts = computed(() => useArtStore().arts);
const currentArt = computed(() => useArtStore().currentArt);
const hasArtSelector = computed(() => currentArt.value.name);

function selectArt(art: Art) {
  navigationActions.changeArt("mainMenu", art);
}
</script>

<template>
  <div class="board-name">
    <template v-if="flexBoard">
      <span class="selector">
        <BaseButton
          variant="ghost"
          color="grey"
          icon-before="board/canvas"
          class="select-button"
          @click="selectFlex"
        >
          {{ flexBoard.flexType.name }}
        </BaseButton>
        <SvgIcon
          name="arrow/right"
          width="20"
          height="20"
          class="arrow-icon"
          aria-hidden="true"
        />
      </span>
      <h1>{{ flexBoard.name }}</h1>
    </template>

    <template v-else>
      <span v-if="hasArtSelector" class="selector">
        <GroupDropdown
          type="art"
          :title="$t('general.arts')"
          :groups="arts"
          :value="currentArt"
          @change="selectArt"
        />
        <SvgIcon
          name="arrow/right"
          width="20"
          height="20"
          class="arrow-icon"
          aria-hidden="true"
        />
      </span>

      <span class="selector">
        <GroupDropdown
          type="team"
          :title="$t('general.teams')"
          :groups="teams"
          :value="currentTeam"
          @change="selectTeam"
        />
        <SvgIcon
          name="arrow/right"
          width="20"
          height="20"
          class="arrow-icon"
          aria-hidden="true"
        />
      </span>

      <h1>{{ $t(boardName) }}</h1>
    </template>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";

.board-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: var(--text-secondary-color);

  .selector {
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    margin-right: 8px;
  }

  .select-button {
    font-weight: font.$weight_normal;
  }
}
</style>
