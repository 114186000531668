import QuadraticSpline from "@/math/QuadraticSpline";
import {
  boardToRelativeSimple,
  relativeToBoard,
} from "@/math/coordinate-systems";
import {
  BoardCoordinate,
  RelativeCoordinate,
  minus,
  plus,
  rectangleAt,
  times,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";

export function findDeleteIconPos(
  from: RelativeCoordinate,
  fromScale: number,
  to: RelativeCoordinate,
  offset: BoardCoordinate, // Offset of the 'from' sticky if it was moved into the viewport when enlarged
): BoardCoordinate | undefined {
  const unscaledSize = useBoardStore().currentBoard().cardSize;
  const scaledSize = times(unscaledSize, fromScale);

  // If the from sticky is moved into the viewport when enlarged, the sticky (fromRect)
  // won't be centered on the link 'from' position. Here, we're correctly positioning the fromRect
  const adjustment = boardToRelativeSimple(offset);
  const adjustedFrom = plus(from, adjustment);

  // make the rectangle a bit bigger than the sticky, this is the space the delete icon will be in
  const fromRect = rectangleAt(adjustedFrom, times(scaledSize, 1.13));
  const toRect = rectangleAt(to, times(unscaledSize, 1.13));
  const spline = QuadraticSpline.forLink(from, to);
  const pos = spline.pointOutsideRectangles(fromRect, toRect);
  if (pos) {
    // Again, if the enlarged sticky is not centered on the link 'from' position,
    // move the delete icons so they appear above the links
    const adjustedPos = minus(pos, adjustment);
    return relativeToBoard(times(minus(adjustedPos, from), 1 / fromScale));
  }
}
