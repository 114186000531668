import { Directive } from "vue";

/**
 * Makes the element on the board scrollable by preventing the scroll event from bubbling up.
 *
 */
export function scrollableOnBoard(): Directive<HTMLElement> {
  function handleScroll(this: HTMLElement, event: Event) {
    event.stopPropagation();

    const target = event.target as HTMLElement;
    if (!isScrollable(target, this, event as WheelEvent)) {
      event.preventDefault();
    }
  }

  return {
    mounted(el) {
      el.addEventListener("scroll", handleScroll);
      el.addEventListener("wheel", handleScroll);
    },

    unmounted(el) {
      el.removeEventListener("scroll", handleScroll);
      el.removeEventListener("wheel", handleScroll);
    },
  };
}

/**
 * Checks if an element is scrollable within a given maximum parent element.
 *
 * @param el - The element to check for scrollability.
 * @param maxParentElement - The maximum parent element within which to check for scrollability.
 * @param event - The wheel event that triggered the check.
 * @returns A boolean indicating whether the element is scrollable.
 */
const isScrollable = (
  el: HTMLElement,
  maxParentElement: HTMLElement,
  event: WheelEvent,
) => {
  let parent = el.parentElement;
  while (parent && parent !== maxParentElement) {
    if (parent.scrollHeight > parent.clientHeight) {
      const isScrollingDown = event.deltaY > 0;
      const isAtBottom =
        parent.scrollTop + parent.clientHeight >= parent.scrollHeight;
      const isAtTop = parent.scrollTop === 0;

      if ((isScrollingDown && !isAtBottom) || (!isScrollingDown && !isAtTop)) {
        return true;
      }
    }
    parent = parent.parentElement;
  }
  return false;
};
