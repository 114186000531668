<script lang="ts" setup>
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { isDependency, isNote, isRisk, isWorkitem } from "@/model/stickyType";
import { injectStrict } from "@/utils/context";

import DependencyFooter from "./components/DependencyFooter/DependencyFooter.vue";
import NoteFooter from "./components/NoteFooter/NoteFooter.vue";
import RiskFooter from "./components/RiskFooter/RiskFooter.vue";
import WorkItemFooter from "./components/WorkItemFooter/WorkItemFooter.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
</script>

<template>
  <div
    :class="[
      'sticky-note-footer',
      { dependency: isDependency(card), note: isNote(card) },
    ]"
  >
    <div v-show="cardMeta.levelOfDetails > 1">
      <WorkItemFooter v-if="isWorkitem(card)" />
      <RiskFooter v-if="isRisk(card)" />
      <NoteFooter v-if="isNote(card)" />
      <DependencyFooter v-if="isDependency(card)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sticky-note-footer {
  height: 36px;
  padding: 8px;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  &.dependency {
    height: 64px;
  }

  // overflow: visible is necessary for reactions animation
  &.note {
    overflow: visible;
  }
}
</style>
