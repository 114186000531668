<script setup lang="ts">
import StatusDot from "@/components/StatusDot.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { StatusClass } from "@/model/baseTypes";
import { FieldChange } from "@/model/change";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";

const props = defineProps<{ stickyId: string; field: FieldChange }>();

function statusClass(status: string): StatusClass {
  // find status class based on current state of the card, is not 100% correct
  // e.g. if card type has changed in the mean time
  // probably does not work with dynamic alm item types
  const card = useCardStore().cards[props.stickyId];
  if (!card) {
    return "undefined";
  }
  return useAlmItemTypeStore().findStatusForCard(
    status,
    card,
    useBoardStore().currentBoard(),
  ).statusClass;
}
</script>

<template>
  <div class="change horizontal">
    <template v-if="field.old">
      <span class="change-value">
        <div class="dot-container">
          <StatusDot :status-class="statusClass(field.old)" />
        </div>
        {{ field.old }}
      </span>
      <SvgIcon
        name="arrow/right-thin"
        width="20"
        height="20"
        role="img"
        :aria-label="$t('label.activityPanel.changedTo')"
      />
    </template>
    <span class="change-value">
      <div class="dot-container">
        <StatusDot :status-class="statusClass(field.new)" />
      </div>
      {{ field.new }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.dot-container {
  width: 16px;
  height: 16px;
}
</style>
