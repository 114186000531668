import { Ref, onMounted, onUnmounted } from "vue";

import {
  WindowCoordinate,
  clientCoord,
  windowCoord,
} from "@/model/coordinates";

const pointerPos: {
  down: WindowCoordinate | null;
  current: WindowCoordinate;
} = {
  down: null,
  current: windowCoord(0, 0),
};

export function usePointerPosition(elem: Ref<HTMLElement>) {
  onMounted(() => {
    elem.value.addEventListener("pointerdown", down);
    elem.value.addEventListener("pointermove", move);
    elem.value.addEventListener("pointerup", up);
    elem.value.addEventListener("pointerout", up);
  });
  onUnmounted(() => {
    if (elem.value) {
      elem.value.removeEventListener("pointerdown", down);
      elem.value.removeEventListener("pointermove", move);
      elem.value.removeEventListener("pointerup", up);
      elem.value.removeEventListener("pointerout", up);
    }
  });

  function down(e: PointerEvent) {
    pointerPos.down = clientCoord(e);
  }

  function move(e: PointerEvent) {
    pointerPos.current = clientCoord(e);
  }

  function up() {
    pointerPos.down = null;
  }

  return {
    pointerPos,
  };
}
