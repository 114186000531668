<script setup lang="ts">
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import TeamSelector from "@/components/card/components/TeamSelector/TeamSelector.vue";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import { Team } from "@/model/session";
import { useServerSettingsStore } from "@/store/serverSettings";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);

const serverSettingsStore = useServerSettingsStore();

// Get the team the card is currently assigned to
const selectedTeam = computed(() => {
  const teamId = card.teamId;
  return teamId ? (useTeamStore().findTeam({ id: teamId }) as Team) : undefined;
});

// Assign the card to the given team
const handleTeamSelect = (team: Team | null) => {
  cardActions.setTeamAction("card", card.id, team?.id || NO_TEAM_ID);

  if (
    team?.id &&
    serverSettingsStore.mirrorAssignedBacklogStickiesToTeamBoard
  ) {
    cardMethods.animate?.("mirroring");
  }

  dropdown.close();
};
</script>

<template>
  <TeamSelector
    :title="$t('action.team')"
    show-clear
    show-current-team
    show-search
    :selected="selectedTeam"
    @select="handleTeamSelect"
  />
</template>
