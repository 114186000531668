import { setI18nLanguage } from "@/i18n";
import { setAmplitudeUserProperty } from "@/utils/analytics/track";
import { addFontFamily } from "@/utils/dom";

export async function setLanguage(lang: string) {
  setAmplitudeUserProperty("language", lang);
  if (lang === "ja") {
    addFontFamily(document.documentElement, "Noto Sans JP");
  }
  await setI18nLanguage(lang);
}
