<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { deleteEvent } from "@/backend/Backend";
import { boardTypeName } from "@/model/baseTypeI18n";
import { TimerEvent } from "@/model/timer";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTimerStore } from "@/store/timer";

import RunningTimer from "./components/RunningTimer.vue";

const { t } = useI18n();

const artTimers = computed(() => filterActiveTimers(useTimerStore().artTimers));

const boardTimers = computed(() =>
  filterActiveTimers(useTimerStore().boardTimers),
);

const timersEditing = computed(() => useTimerStore().active);

const boardName = computed(() =>
  boardTypeName(useBoardStore().currentBoard().type),
);
const artName = computed(() =>
  t("general.namedArt", { name: useArtStore().currentArt.name }),
);

const filterActiveTimers = (timers: TimerEvent[]) => {
  return timers.filter((timer) => {
    if (timer.data.state === "running") {
      return timer;
    }
  });
};

const deleteTimer = (id: string) => deleteEvent(id);
</script>

<template>
  <div class="timers">
    <RunningTimer
      v-for="timer in artTimers"
      :key="timer.id"
      :timer="timer.data"
      :timer-scope="artName"
      :is-editing="timersEditing"
      @delete="deleteTimer(timer.id)"
    />
    <RunningTimer
      v-for="timer in boardTimers"
      :key="timer.id"
      :timer="timer.data"
      :timer-scope="$t(boardName)"
      :is-editing="timersEditing"
      @delete="deleteTimer(timer.id)"
    />
  </div>
</template>

<style scoped>
.timers {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
