<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { Card } from "@/model/card";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

import SearchResultsItem from "./Search/SearchResultsItem.vue";

const props = defineProps<{
  card: Card;
  showTeam?: boolean;
  showStatus?: boolean;
}>();

const { t } = useI18n();

const cardContent = computed(() => {
  const teamName = !props.showTeam
    ? ""
    : props.card.teamId
      ? useTeamStore().findTeam({ id: props.card.teamId })?.name
      : t("general.noTeam");
  return {
    id: props.card.id,
    text: props.card.text,
    color: props.card.type.color,
    type: props.card.type.name,
    flag: props.card.flagType,
    almId: props.card.almId,
    status: props.card.status,
    teamName,
    iterationName: useSessionStore().getIterationName(props.card.iterationId),
  };
});
</script>

<template>
  <SearchResultsItem
    class="search-results-item"
    :item="cardContent"
    :show-status="showStatus"
  />
</template>

<style lang="scss" scoped>
.search-results-item {
  padding: 0.625rem 0 !important;
}
</style>
