<template>
  <div class="max-length-textarea">
    <base-textarea
      v-bind="$attrs"
      ref="baseTextarea"
      v-model="textValue"
      class="base-textarea"
      :class="{ error: !isValid, 'no-border': !bordered }"
      :maxlength="maxlength"
      :readonly="isReadonly"
      @update:model-value="validate"
      @blur="handleBlur"
      @focus="handleFocus"
    />
    <span v-if="isFocused && !isReadonly" class="message">
      <p
        class="length"
        :class="{ error: isLengthExceeded }"
        :aria-label="$t('textarea.lengthIndicator.aria')"
      >
        {{
          $t("textarea.lengthIndicator", { textLength, maxLength: maxlength })
        }}
      </p>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from "vue-facing-decorator";

import BaseTextarea from "./BaseTextarea.vue";

@Component({
  components: { BaseTextarea },
  inheritAttrs: false,
  emits: ["validate", "update:modelValue"],
})
export default class MaxLengthTextarea extends Vue {
  @Prop({ type: Number, required: true }) readonly maxlength!: number;
  @Prop({ type: String, default: "" }) readonly modelValue!: string;
  @Prop({ type: Boolean, default: false }) readonly bordered!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isReadonly!: boolean;
  @Ref("baseTextarea") readonly baseTextareaElem!: BaseTextarea;

  isFocused = false;
  isValid = true;

  validate() {
    this.isValid = !this.isLengthExceeded;
    this.$emit("validate", this.isValid);
  }

  handleFocus() {
    this.isFocused = true;
    this.validate();
  }

  handleBlur() {
    this.isFocused = false;
  }

  focus() {
    this.baseTextareaElem.focus();
  }

  get isLengthExceeded() {
    return this.textLength > this.maxlength;
  }

  get textLength() {
    return this.textValue.length;
  }

  get textValue() {
    return this.modelValue;
  }

  set textValue(newValue) {
    this.$emit("update:modelValue", newValue);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.max-length-textarea {
  width: 100%;
  display: flex;
  position: relative;

  .message {
    position: absolute;
    right: 5px;
    bottom: 2px;
    width: 60px;
    text-align: center;
    padding: 0.5rem 0;

    p {
      margin: 0.125rem 0 0;
      font-size: font.$size-small;
      vertical-align: middle;

      &.error {
        color: colors-old.$input-error-color;
      }
    }
  }

  .base-textarea {
    padding-right: 65px;

    &.no-border {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: colors-old.$placeholder-color;
    }
  }
}
</style>
