<script lang="ts" setup>
import StatusDot from "@/components/StatusDot.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import StickyStatus from "../StickyStatus/StickyStatus.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
</script>

<template>
  <AttributeChip
    name="status-chip"
    :tooltip="card.status?.name"
    :readonly="cardMeta.isReadonly"
    active
  >
    <template #icon>
      <span class="status-dot">
        <StatusDot :status-class="card.status?.statusClass || 'undefined'" />
      </span>
    </template>
    <StickyStatus trigger="attribute-chip" />
  </AttributeChip>
</template>

<style lang="scss" scoped>
.status-dot {
  height: 14px;
  width: 14px;
}
</style>
