<script lang="ts" setup>
import { computed } from "vue";

import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useArtStore } from "@/store/art";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import ArtSelectDropdown from "./ArtSelectDropdown.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const arts = computed(() => useArtStore().arts);
const selected = computed(() => useArtStore().artById(card.artId));
</script>

<template>
  <AttributeChip
    name="art-select-chip"
    :text="selected?.name"
    :text-placeholder="$t('actions.art')"
    :tooltip="$t('actions.art')"
    :readonly="cardMeta.isReadonly"
    :dropdown-props="{ width: 'md' }"
  >
    <ArtSelectDropdown :selected="selected" :arts="arts" />
  </AttributeChip>
</template>
