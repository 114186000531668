<script setup lang="ts">
import { computed, onMounted } from "vue";

import { getAlmInfo, loadSessionAlmStatus } from "@/backend/Backend";
import { cockpitConnectionUrl } from "@/cockpitUrls";
import { i18n } from "@/i18n";
import { InfoLevel } from "@/model/baseTypes";
import { SessionAlmStatus } from "@/model/session";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";

import MenuItemDelimiter from "./menu/components/MenuItemDelimiter.vue";
import BaseTooltip from "./ui/BaseTooltip/BaseTooltip.vue";
import InfoMarker from "./ui/InfoMarker/InfoMarker.vue";

onMounted(async () => await loadSessionAlmStatus());

const almType = getAlmInfo().name;
const almStatusMapping: Record<
  SessionAlmStatus,
  { message: string; type: InfoLevel }
> = {
  stopped: {
    message: i18n.global.t("almsync.stopped", { almType: almType }),
    type: "default",
  },
  error: {
    message: i18n.global.t("almsync.disconnected", { almType: almType }),
    type: "error",
  },
  starting: { message: i18n.global.t("almsync.resync"), type: "ok" },
  running: { message: "", type: "ok" },
};

const cockpitAllowed = computed(() => useUserStore().isAllowed("cockpit"));
const status = computed(
  () =>
    almStatusMapping[useSessionStore().session.current.almStatus || "running"],
);
const showTooltip = computed(
  () => status.value.type === "error" && cockpitAllowed.value,
);
</script>

<template>
  <div id="alm-status" data-no-screenshot>
    <BaseTooltip
      :key="showTooltip ? 'withTooltip' : 'withoutTooltip'"
      position="bottom"
    >
      <transition name="fade">
        <div
          v-if="status.message"
          data-testid="alm-status"
          class="message"
          :class="{
            'error-message': status.type === 'error' && cockpitAllowed,
          }"
        >
          <span style="position: relative">
            <InfoMarker :level="status.type" :top="2" />
          </span>
          <a
            v-if="status.type === 'error' && cockpitAllowed"
            target="_blank"
            :href="cockpitConnectionUrl()"
            class="fix"
          >
            {{ status.message }}
          </a>
          <span v-else>{{ status.message }}</span>
        </div>
      </transition>
      <template v-if="showTooltip" #content>
        <div class="alm-status-tooltip">
          {{ $t("almSync.tooltip") }}
        </div>
      </template>
    </BaseTooltip>
    <MenuItemDelimiter v-if="status.message" />
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

#alm-status {
  .message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: var(--text-secondary-color);
    border-radius: variables.$button-icon-radius;
    height: 40px;
    font-size: 14px;
    font-weight: font.$weight-semi-bold;
    line-height: 18px;
    padding: 10px 5px;
    cursor: default;

    &:hover {
      background-color: var(--light-background-color);
    }

    .fix {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .error-message {
    padding: 10px 15px;
  }

  .error-message:hover {
    background-color: colors-old.$light-background-color;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.75s ease-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}

.alm-status-tooltip {
  width: 240px;
}
</style>
