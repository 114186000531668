import color from "@/styles/color.module.scss";

import { BoardType, InfoLevel, StatusClass } from "./baseTypes";

export const linkColors: { [state in InfoLevel]: string } = {
  default: color.textSecondary,
  ok: color.ok,
  warn: color.altWarn,
  error: color.altError,
};

export const flagColors = [
  color.flag1,
  color.flag2,
  color.flag3,
  color.flag4,
  color.flag5,
  color.flag6,
];

type LinkBoardType = Extract<BoardType, "program" | "risk" | "team" | "flex">;

export const normalLinkColors: {
  [state in LinkBoardType]: string;
} = {
  program: color.link2,
  risk: color.link1,
  team: color.link1,
  flex: color.link1,
};

export const statusClassColors: {
  [key in StatusClass]: string;
} = {
  undefined: color.statusClassUndefined,
  todo: color.statusClassTodo,
  "in-progress": color.statusClassProgress,
  done: color.statusClassDone,
};
