import { adminUrl } from "@/Environment";
import { getAlmConnectionId, getAlmInfo } from "@/backend/Backend";
import { BoardType } from "@/model/baseTypes";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";

export function cockpitSessionUrl(boardType?: BoardType) {
  const sessionId = useSessionStore().session.selected?.id;
  const path = boardTypeSetupPath[boardType!] || "generalInformation";
  return sessionId
    ? `${adminUrl}/edit-session?id=${sessionId}&path=${path}`
    : `${adminUrl}/safepiplannings`;
}

export function cockpitProfileUrl(path?: string) {
  const sessionId = useSessionStore().session.selected?.id;
  path = encodeURIComponent(path || "");
  return sessionId && path
    ? `${adminUrl}/profile?mapping=${sessionId}&path=${path}`
    : `${adminUrl}/profile`;
}

export function cockpitConnectionUrl() {
  const almConnectionId = getAlmConnectionId() || "";
  const almPath = getAlmInfo().cockpitPath;
  return `${adminUrl}/alm-connections/${almPath}/${almConnectionId}`;
}

export function cockpitBillingUrl() {
  return useUserStore().isTraining
    ? `${adminUrl}/trainingBilling`
    : `${adminUrl}/billing`;
}

const boardTypeSetupPath: { [type in BoardType]?: string } = {
  backlog: "programBacklogBoard",
  program: "programBoard",
  solution: "solutionPlanningBoard",
  solution_backlog: "solutionBacklogBoard",
  risk: "riskBoard",
  team: "teamBoards",
  flex: "flexBoards",
};
