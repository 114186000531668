import { namedKey } from "@/Shortcuts";
import MirrorModal from "@/components/modal/MirrorModal.vue";
import StickyLinkModal from "@/components/modal/StickyLinkModal.vue";
import TeamModal from "@/components/modal/TeamModal.vue";
import { ExecutionMode } from "@/model/baseTypes";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { selectOnClickActivated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { Action, action, defineActions, getActionTrigger } from "./actions";

export const selectionActions = defineActions("selection", {
  selectOnClick: action(selectOnClick, {
    name: /*$t*/ "action.selectStickies",
    shortcut: namedKey("Shift"),
    icon: "selection/select",
  }),
  alignHorizontal: action(
    (mode?) => execute(mode, useSelectionStore().alignHorizontal),
    {
      name: /*$t*/ "action.alignHorizontal",
      icon: "selection/align-horizontal",
      startPreview,
      stopPreview,
    },
  ),
  alignVertical: action(
    (mode?) => execute(mode, useSelectionStore().alignVertical),
    {
      name: /*$t*/ "action.alignVertical",
      icon: "selection/align-vertical",
      startPreview,
      stopPreview,
    },
  ),
  distributeHorizontal: action(
    (mode?) => execute(mode, useSelectionStore().distributeHorizontal),
    {
      name: /*$t*/ "action.distributeHorizontal",
      icon: "selection/distribute-horizontal",
      startPreview,
      stopPreview,
    },
  ),
  distributeVertical: action(
    (mode?) => execute(mode, useSelectionStore().distributeVertical),
    {
      name: /*$t*/ "action.distributeVertical",
      icon: "selection/distribute-vertical",
      startPreview,
      stopPreview,
    },
  ),
  lineup: action((mode?) => execute(mode, useSelectionStore().lineup), {
    name: /*$t*/ "action.lineup",
    icon: "selection/lineup",
    startPreview,
    stopPreview,
  }),
  link: action(
    (_) => {
      useModalStore().open(StickyLinkModal, {
        attrs: { cardIds: useBoardStore().selectedStickyIds },
      });
    },
    {
      name: /*$t*/ "action.linkMultiple",
      icon: "link",
    },
  ),
  mirror: action(() => useModalStore().open(MirrorModal), {
    name: /*$t*/ "action.mirrorMultiple",
    icon: "mirror",
  }),
  setTeam: action(() => useModalStore().open(TeamModal), {
    name: /*$t*/ "action.team",
    icon: "team",
  }),
  undo: action(() => useSelectionStore().undo(), { icon: "undo" }),
});

let previewing = false;
let previewTimeout = 0;

function startPreview(action: Action<[ExecutionMode]>) {
  previewTimeout = window.setTimeout(() => {
    previewing = true;
    action("mainMenu", "preview");
  }, 1000);
}

function execute(
  mode: ExecutionMode | undefined,
  action: (mode: ExecutionMode) => void,
) {
  if (mode) {
    action(mode);
  } else {
    window.clearTimeout(previewTimeout);
    action(previewing ? "confirm" : "normal");
    previewing = false;
  }
}

function stopPreview() {
  window.clearTimeout(previewTimeout);
  if (previewing) {
    previewing = false;
    selectionActions.undo("mainMenu");
  }
}

function selectOnClick(trigger?: "board-actions-menu") {
  const isActive = useSelectionStore().selecting === "click";
  if (!isActive) {
    const t = trigger || getActionTrigger(selectOnClick);
    trackEvent(selectOnClickActivated(t));
  }

  useSelectionStore().toggleSelectOnClick();
}
