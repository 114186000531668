<script lang="ts" setup>
import { computed } from "vue";

import {
  cardStatusDistribution,
  dependencyCardStatusDistribution,
} from "@/components/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution.vue";
import { cardKey } from "@/components/card/injectKeys";
import { isBacklogBoard } from "@/model/board";
import { isDependency } from "@/model/stickyType";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);

const isProgressBarVisible = computed(
  () =>
    (isDependency(card) || isBacklogBoard(card.type.origin)) &&
    useWorkModeStore().isExecutionMode,
);

const currentStatusDistribution = computed(() => {
  return isDependency(card)
    ? dependencyCardStatusDistribution(card)
    : cardStatusDistribution(card);
});
</script>

<template>
  <div v-if="isProgressBarVisible" class="sticky-note-progress">
    <StatusDistribution
      :tooltip-offset="[0, 10]"
      :value="currentStatusDistribution"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index";
@use "./variables";

.sticky-note-progress {
  position: absolute;
  left: 0;
  right: 0;
  height: variables.$height;
  bottom: variables.$height * -1;
  z-index: z-index.$progress-bar;

  :deep(.stacked-bar-chart) {
    position: absolute;
  }

  :deep(.bordered) {
    border-radius: unset;
    border: unset;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
</style>
