<script setup lang="ts">
import { InfoLevel } from "@/model/baseTypes";
import { Icon } from "@/types/icon";

import SvgIcon from "./SvgIcon/SvgIcon.vue";

defineProps<{ type: InfoLevel }>();

const icon: { [type in InfoLevel]: Icon } = {
  default: "iteration/default",
  ok: "iteration/ok",
  warn: "iteration/warn",
  error: "iteration/error",
};

const label: { [type in InfoLevel]: string } = {
  default: /*$t*/ "label.iterationFlag.default",
  ok: /*$t*/ "label.iterationFlag.ok",
  warn: /*$t*/ "label.iterationFlag.warn",
  error: /*$t*/ "label.iterationFlag.error",
};
</script>

<template>
  <SvgIcon
    role="img"
    :name="icon[type]"
    :class="['iteration-flag', type]"
    :aria-label="$t(label[type])"
  />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.iteration-flag {
  &.default {
    color: colors-old.$iteration-default-color;
  }

  &.ok {
    color: colors-old.$iteration-success-color;
  }

  &.warn {
    color: colors-old.$iteration-warning-color;
  }

  &.error {
    color: colors-old.$iteration-error-color;
  }
}
</style>
