<template>
  <BaseTooltip position="bottom" class="tooltip">
    <BaseButton
      v-show="show"
      id="risky-links"
      data-no-screenshot
      variant="ghost"
      :activated="active"
      :aria-label="riskyLinkTooltipText"
      @click="showRiskyLinks"
    >
      <flash-icon
        v-if="linkTypeCount.critical"
        link-state="error"
        data-testid="critical-link-icon"
      />
      <flash-icon
        v-if="linkTypeCount.risky"
        link-state="warn"
        data-testid="risky-link-icon"
      />
    </BaseButton>
    <template #content>
      {{ riskyLinkTooltipText }}
    </template>
  </BaseTooltip>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import EventBusUser from "@/mixins/EventBusUser";
import { replaceQuery } from "@/router/navigation";
import type { SearchQuery } from "@/router/types";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

import FlashIcon from "./FlashIcon.vue";
import BaseButton from "./ui/BaseButton/BaseButton.vue";
import BaseTooltip from "./ui/BaseTooltip/BaseTooltip.vue";

@Component({ components: { FlashIcon, BaseTooltip, BaseButton } })
export default class RiskyLinks extends mixins(EventBusUser) {
  active = false;
  store = useSearchMenuStore();

  mounted() {
    this.onSearch((query: SearchQuery) => {
      this.active = !!query.link;
    });
  }

  get show() {
    return useLinkStore().hasRiskyLinks;
  }

  get linkTypeCount() {
    return useLinkStore().riskyLinkCount;
  }

  get riskyLinkTooltipText() {
    return this.linkTypeCount.critical > 0 && this.linkTypeCount.risky === 0
      ? this.$t("linkState.criticalLinks")
      : this.$t("linkState.riskyLinks");
  }

  showRiskyLinks() {
    const criticalLink = this.linkTypeCount.critical ? "error" : "";
    const warnLink = this.linkTypeCount.risky ? "warn" : "";
    const riskyLinks = [criticalLink, warnLink].filter(Boolean).join(",");

    replaceQuery({ search: { link: riskyLinks } });
    this.store.isSearchActive = true;
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";

#risky-links {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: transparent;
  height: 40px;
  border-radius: variables.$common-border-radius;

  .tooltip {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &:not(.active):hover {
    background-color: var(--light-background-color);
  }

  img {
    margin-right: 5px;
  }
}
</style>
