<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() =>
  isActionVisible("activity", card, board.value),
);
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="activity"
    :tooltip-text="$t('cardAction.activity')"
    :aria-label="$t('label.actionMenu.activity')"
    @click="cardActions.toggleActivity('card', card.id)"
  >
    <SvgIcon name="action-menu/activity" width="20" height="20" />
  </ActionMenuItem>
</template>
