<template>
  <div>
    <transition name="faster-fade" mode="out-in">
      <button
        v-if="shouldShowSummary"
        :id="`description-summary-${componentId}`"
        class="description-summary"
        :aria-label="$t('label.objective.summaryButton', { summary })"
        @click="toggleExpand"
      >
        <span class="description-summary-text">{{ summary }}</span>
        <span v-if="showButtons" class="see-more-link">
          {{ $t("textarea.seeMore") }}
        </span>
      </button>
      <div v-else>
        <base-textarea
          :id="`description-textarea-${componentId}`"
          v-bind="$attrs"
          v-model="textValue"
          class="description-textarea"
          :readonly="isReadonly"
          :placeholder="$t('objectives.description.placeholder')"
          :class="{ expanded: expanded }"
          @focus="onFocus"
        />
        <button v-if="showButtons" class="see-less-link" @click="toggleExpand">
          <span>{{ $t("textarea.seeLess") }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Options as Component } from "vue-class-component";
import { Prop, Vue, Watch } from "vue-property-decorator";

import { objectId } from "@/utils/objectId";

import BaseTextarea from "./BaseTextarea.vue";

@Component({
  components: { BaseTextarea },
  inheritAttrs: false,
  emits: ["update:modelValue"],
})
export default class ExpandableTextarea extends Vue {
  @Prop({ type: String, required: true }) readonly modelValue!: string;
  @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;
  @Prop({ type: Number, default: 90 }) readonly summaryMaxLength!: number;
  @Prop({ type: Boolean, default: false }) readonly expandDescription!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isReadonly!: boolean;

  expanded = false;
  componentId = objectId();

  @Watch("expandDescription", { immediate: true })
  onExpandDescriptionChange(newVal: boolean) {
    this.expanded = newVal;
  }

  toggleExpand() {
    this.expanded = !this.expanded;

    // Ensure focus moves to the correct element (textare when expanded,
    // summary when collapsed)
    setTimeout(() => {
      const targetEl = this.$el.querySelector(
        this.expanded
          ? `#description-textarea-${this.componentId}`
          : `#description-summary-${this.componentId}`,
      );
      targetEl?.focus();
    }, 150 + 50); // Timeout should exceed faster-fade transition duration (150ms)
  }

  onFocus() {
    this.expanded = true;
  }

  mounted() {
    this.initializeExpandedState();
  }

  initializeExpandedState() {
    if (this.textValue === "") {
      this.expanded = true;
    }
  }

  get shouldShowSummary() {
    return (
      this.textValue !== "" &&
      !this.expanded &&
      (!this.expandDescription || this.showButtons)
    );
  }
  get summary() {
    return this.textValue.split("\n")[0];
  }
  get showButtons() {
    const totalLength = this.textValue.length;
    const hasNewLines = this.textValue.includes("\n");
    return (
      (totalLength > this.summaryMaxLength || hasNewLines) &&
      this.summaryMaxLength > 0
    );
  }

  get textValue() {
    return this.modelValue;
  }

  set textValue(newValue) {
    this.$emit("update:modelValue", newValue);
  }
}
</script>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.description-summary {
  padding: 0.5rem;
  color: colors-old.$text-primary-color;
  display: flex;
  align-items: center;
  max-width: 100%;
  outline: revert;
  cursor: pointer;

  &-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.see-more-link,
.see-less-link {
  cursor: pointer;
  color: colors-old.$alt-primary-color;
  outline: revert;
}

.see-less-link {
  padding-left: 0.5rem;
}
</style>
