<script setup lang="ts">
import { computed } from "vue";

import { useLoadingStore } from "@/store/loading";

/**
 * global: indicator is shown globally in the center of the window (instead of inline)
 * background: indicator has the lowest z-index and is only shown as long as no other component is loaded
 */
const props = defineProps<{
  inverted?: boolean;
  global?: boolean;
  background?: boolean;
}>();

const progress = computed(() => useLoadingStore().progress);
const showProgress = computed(
  () => props.background && !useLoadingStore().ended,
);
</script>

<template>
  <div class="loading-indicator" :class="{ inverted, global, background }">
    <div v-if="showProgress" class="progress">
      <div class="total" />
      <div class="started" :style="{ width: progress.started * 100 + '%' }" />
      <div class="finished" :style="{ width: progress.finished * 100 + '%' }" />
    </div>
    <div v-else class="icon" />
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.loading-indicator {
  pointer-events: none;

  .progress {
    width: 200px;
    position: relative;

    & > div {
      position: absolute;
      height: 20px;
      border-radius: 10px;
    }

    .total {
      background-color: colors-old.$status-class-todo-color;
      width: 100%;
    }

    .started {
      background-color: colors-old.$status-class-progress-color;
    }

    .finished {
      background-color: colors-old.$status-class-done-color;
    }
  }

  &.global {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: z-index.$board;
  }

  &.background {
    z-index: -1;
  }

  .icon {
    border: 0.3em solid transparent;
    border-top-color: colors-old.$primary-color;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 1.5em;
    height: 1.5em;
  }

  &.inverted {
    .icon {
      border-top-color: colors-old.$back-color;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
