<script lang="ts" setup>
import { computed } from "vue";

import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import TeamDropdown from "../../../TeamDropdown/TeamDropdown.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const team = computed(() => {
  return useTeamStore().findTeam({ id: card.teamId ?? undefined });
});
</script>

<template>
  <AttributeChip
    name="team-select-chip"
    :text="team?.name"
    text-placeholder="Team"
    :tooltip="$t('action.team')"
    :readonly="cardMeta.isReadonly"
  >
    <TeamDropdown />
  </AttributeChip>
</template>
