import { noop } from "lodash-es";
import { onMounted, ref } from "vue";

import { environment } from "@/Environment";

export function useServicePopups() {
  const openServices = ref<() => void>(noop);
  const showServices = ref(false);

  onMounted(() => {
    const host = document.location.host;
    if (
      environment !== "production" &&
      (host.startsWith("local.piplanning.io") ||
        host.startsWith("app.local.piplanning.io"))
    ) {
      showServices.value = true;
      const popups: Array<{ url: string; window?: Window | null }> = [
        { url: "https://api.local.piplanning.io" },
        { url: "https://nats.local.piplanning.io" },
        { url: "https://local.piplanning.io" },
      ];
      window.addEventListener("beforeunload", closeAll);

      let current = 0;
      setInterval(() => {
        if (popups[current].window?.closed) {
          if (current + 1 < popups.length) {
            open(current + 1);
          }
        }
      }, 500);

      openServices.value = () => {
        closeAll();
        open(0);
      };

      // eslint-disable-next-line no-inner-declarations
      function open(index: number) {
        current = index;
        popups[current].window = window.open(
          popups[current].url,
          "nats",
          "width=1000,height=2000",
        );
      }

      // eslint-disable-next-line no-inner-declarations
      function closeAll() {
        popups.forEach((popup) => popup.window?.close());
      }
    }
  });

  return { showServices, openServices };
}
