import { defineStore } from "pinia";

import { License, LicenseType } from "@/model/session";

import { useUserStore } from "./user";

export const useLicenseStore = defineStore("license", {
  state: (): { license: License } => ({
    license: {
      daysTillExpiration: null,
      usable: true,
      plan: "standard",
      tracking: false,
      type: "trial",
    },
  }),
  getters: {
    isExpired: (state) => {
      const expire = state.license.daysTillExpiration;
      return expire !== null && expire <= 0;
    },
    willExpireIn: (state) => {
      const expire = state.license.daysTillExpiration;
      if (expire !== null && expire < 30 && expire > 0) {
        return expire;
      }
    },
  },
  actions: {
    setLicense(e: {
      validDays: number;
      plan: License["plan"];
      tracking: boolean;
      type: LicenseType;
    }) {
      this.license.daysTillExpiration = e.validDays;
      this.license.usable =
        e.validDays >= 0 || useUserStore().isAllowed("useWithoutLicense");
      this.license.plan = e.plan;
      this.license.tracking = e.tracking;
      this.license.type = e.type;
    },
  },
});
