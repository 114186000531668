<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getAlmInfo } from "@/backend/Backend";
import { NoSuccessError } from "@/backend/NoSuccessError";
import { sender } from "@/backend/Sender";
import { TimeoutError } from "@/backend/TimeoutError";
import { captureException } from "@/sentry";
import { useArtStore } from "@/store/art";
import { useModalStore } from "@/store/modal";

import ConfirmModal from "./ConfirmModal.vue";

const { t } = useI18n();
const error = ref("");
const artId = computed(() => useArtStore().art?.current?.id);
const almName = getAlmInfo().name;

async function sync() {
  error.value = "";
  try {
    await sender.syncBacklog(
      artId.value ? parseInt(artId.value, 10) : undefined,
    );
    setTimeout(useModalStore().hide, 1000);
  } catch (fail) {
    if (fail instanceof NoSuccessError) {
      error.value = t("backlogSync.failedSync", {
        errorMessage: fail.response.message,
      });
    } else if (fail instanceof TimeoutError) {
      error.value = t("backlogSync.timeout");
    } else {
      error.value = t("backlogSync.failedSync");
      captureException(fail);
    }
  }
}
</script>

<template>
  <ConfirmModal
    min-height="220px"
    :question="$t('backlogBoard.confirmManualUpdate', { almName })"
    :action-label="$t('iterationSync.updateStickies')"
    :error="error"
    :action="sync"
  >
    {{ $t("backlogBoard.stickyOverwrite", { almName }) }}
  </ConfirmModal>
</template>
