import { LRUMap } from "lru_map";

import { fontsLoaded } from "@/utils/dom";

const mapKey = "pipFontCache";
const versionKey = "pipFontCacheVersion";
const version = "10";
const capacity = 10000;
const cache = loadCache();

// font might not have been loaded, so wait with font size calculation until ready
function calcPromise<EDIT extends boolean>(
  calc: () => FontData<EDIT>,
): Promise<FontData<EDIT>> {
  return fontsLoaded().then(calc);
}

interface FontInfo {
  raw?: RawFontData;
  text?: TextFontData;
}

export type FontData<EDIT extends boolean> = EDIT extends true
  ? RawFontData
  : TextFontData;

// font data for text with unprocessed links (used for edit mode)
export interface RawFontData {
  cache: boolean;
  size: number;
}

// font data for text with processed links (used for non-edit mode)
export interface TextFontData extends RawFontData {
  htmlLines: string[];
  textLines: string[];
}

export function fontSizeCache<EDIT extends boolean>(
  text: string,
  width: number,
  height: number,
  edit: EDIT,
  scale: boolean,
  font: string,
  calcFontData: () => FontData<EDIT>,
): Promise<FontData<EDIT>> {
  const ratio = Math.round((100 * width) / height);
  const key = `${ratio}/${font}/${scale ? "1" : "0"}/${text}`;
  const info = getInfo(key);
  const sub = edit ? "raw" : "text";
  const cached = info[sub] as FontData<EDIT>;

  if (cached && isFinite(ratio)) {
    return Promise.resolve(cached);
  }

  return calcPromise(() => {
    const fontData = calcFontData();
    if (!fontData.size) {
      fontData.size = edit ? 100 : 500;
      return fontData;
    }

    if (fontData.cache) {
      (info[sub] as FontData<EDIT>) = fontData;
      saveCache();
    }

    return fontData;
  });
}

function getInfo(key: string): FontInfo {
  let info = cache.get(key);
  if (!info) {
    info = {};
    cache.set(key, info);
  }
  return info;
}

function loadCache(): LRUMap<string, FontInfo> {
  if (localStorage.getItem(versionKey) === version) {
    return doLoadCache(localStorage.getItem(mapKey));
  }
  localStorage.setItem(versionKey, version);
  return doLoadCache(null);
}

function doLoadCache(state: string | null): LRUMap<string, FontInfo> {
  try {
    const entries = state
      ? JSON.parse(state).map((it: { key: string; value: FontInfo }) => [
          it.key,
          it.value,
        ])
      : undefined;
    return new LRUMap(capacity, entries);
  } catch {
    return new LRUMap(capacity);
  }
}

let saving = false;

function saveCache() {
  if (!saving) {
    saving = true;
    setTimeout(() => {
      saving = false;
      localStorage.setItem(mapKey, JSON.stringify(cache.toJSON()));
    }, 5000);
  }
}

export function clearCache() {
  cache.clear();
  saveCache();
}
