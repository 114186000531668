import { defineStore } from "pinia";

import { relativeToWindow, windowToRelative } from "@/math/coordinate-systems";
import {
  WindowCoordinate,
  minus,
  relativeCoord,
  windowCoord,
} from "@/model/coordinates";
import { ZoomedAppSize } from "@/model/size";

export const useZoomStore = defineStore("zoom", {
  state: () => ({
    zooming: false,
    useZoomLayer: true,
    factor: 1, // only updated at the end of zooming
    dynamicFactor: 1, // also updated during zooming
    // the point we are zooming to/from
    center: {
      absolute: windowCoord(0, 0),
      relative: relativeCoord(0, 0),
    },
  }),
  getters: {
    zoomingWithLayer: (state) => state.zooming && state.useZoomLayer,
  },
  actions: {
    setDynamicZoomFactor(factor: number) {
      this.dynamicFactor = Math.round(100 * factor) / 100;
    },
    setZoomFactor(factor: number) {
      this.setDynamicZoomFactor(factor);
      this.updateZoomFactor();
    },
    updateZoomFactor() {
      this.factor = this.dynamicFactor;
    },
    startZoom(center: WindowCoordinate) {
      this.zooming = true;
      this.center = { absolute: center, relative: windowToRelative(center) };
    },
    endZoom() {
      this.zooming = false;
    },
    scrollToCenter(size: ZoomedAppSize) {
      const windowCoord = relativeToWindow(this.center.relative, size);
      const scroll = minus(windowCoord, this.center.absolute);
      window.scrollTo(Math.round(scroll.x), Math.round(scroll.y));
    },
  },
});
