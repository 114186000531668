<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import AssigneeItemTooltip from "@/components/Search/tooltips/AssigneeItemTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectUser from "@/components/ui/SelectUser/SelectUser.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedAssignees,
  set: (values: Array<{ id: string }>) => {
    store.selectedAssigneeIds = new Set(values.map((val) => val.id));
  },
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    is-aria-menu
    @open="trackFilterOpen('assignee')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="user"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', { value: $t('general.assignees') })
        "
      >
        <template #tooltip>
          <AssigneeItemTooltip :assignees="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectUser
      v-model="selection"
      :values="store.allAssignees"
      :header="$t('general.assignees')"
      scrollable
    />
  </DropdownMenu>
</template>
