import axios from "axios";
import { I18nOptions, createI18n } from "vue-i18n";

import en from "@/translations/en.json";
import { reloadOnMissingChunks } from "@/utils/import";

// There are 2 default languages:
// English and "none" which means it shows the i18n keys instead of the translations.
// This can be helpful to quickly correct a translation error.
// To quickly switch language, one can use the `lang` URL query parameter.

const DEFAULT_LOCALE = "en";
const FALLBACK_LOCALE = "en";

const none = Object.fromEntries(Object.entries(en).map(([key]) => [key, key]));
const loadedLanguages = ["en", "none"];

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: { en, none } as I18nOptions["messages"],
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

export async function setI18nLanguage(lang: string) {
  try {
    if (i18n.global.locale.value !== lang && !loadedLanguages.includes(lang)) {
      const messages = await import(`./translations/${lang}.json`).catch(
        reloadOnMissingChunks,
      );
      i18n.global.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);
    }
    i18n.global.locale.value = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html")?.setAttribute("lang", lang);
  } catch (e) {
    // ignore when loading messages goes wrong
  }
}
