<script lang="ts" setup>
import { computed } from "vue";

import TypeDot from "@/components/TypeDot.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { isDependency } from "@/model/stickyType";
import { injectStrict } from "@/utils/context";

import * as actions from "../../actions";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import StickyTypeChange from "./StickyTypeChange.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const disabled = computed(() => {
  // status of dependency stickies can not be changed
  if (isDependency(card)) return true;
  // can not change the type of stickies that were mirrored
  return actions.cardWasCreatedOnDifferentBoard(card, board.value);
});
</script>

<template>
  <ActionMenuItem
    name="type"
    :tooltip-text="
      disabled
        ? $t('stickyNote.stickyTypeChangeDisabled')
        : $t('stickyNote.stickyType')
    "
    :disabled="disabled"
    :aria-label="$t('label.actionMenu.stickyType')"
    aria-controls="sticky-type-dropdown"
  >
    <TypeDot :color="card.type.color" size="medium" />
    <template #dropdown>
      <StickyTypeChange
        id="sticky-type-dropdown"
        :header="$t('stickyNote.stickyType')"
        trigger="action-menu"
      />
    </template>
  </ActionMenuItem>
</template>
