<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";

import { Color } from "@/model/baseTypes";
import color from "@/styles/color.module.scss";
import { contrastCssColor, toCssColor } from "@/utils/color";

import { PieData } from "./PieChart";

const props = defineProps<{
  value: PieData[];
  total: number;
  size: number;
  select?: string;
  hole?: boolean;
  zoom?: number;
}>();

const canvasRef = ref<HTMLCanvasElement>();

const markDist = 0.15;
const holeSize = 0.4;

type Mark = "before" | "this" | "after" | "single" | "none";

function createPattern(width: number, color: string) {
  const size = 16;
  const c = document.createElement("canvas");
  c.width = size;
  c.height = size;
  const ctx = c.getContext("2d")!;

  const x0 = size + width;
  const x1 = -width;
  const y1 = -width;
  const y0 = size + width;

  ctx.strokeStyle = color;
  ctx.lineWidth = width;
  ctx.beginPath();
  ctx.moveTo(x0, y0);
  ctx.lineTo(x1, y1);
  ctx.moveTo(x0 - size, y0);
  ctx.lineTo(x1 - size, y1);
  ctx.moveTo(x0 + size, y0);
  ctx.lineTo(x1 + size, y1);
  ctx.stroke();
  return c;
}

const emptyPattern = createPattern(4, color.darkDivider);

let ctx!: CanvasRenderingContext2D;

const width = computed(() => {
  if (props.zoom && props.zoom < 2) {
    return (props.size * (window.devicePixelRatio || 1)) / 1.5;
  }
  return props.size * (window.devicePixelRatio || 1);
});

watch(() => props, init, { deep: true });

onMounted(() => {
  ctx = canvasRef.value!.getContext("2d")!;
  init();
});

function init() {
  const canvas = canvasRef.value!;
  canvas.width = width.value;
  canvas.height = width.value;
  canvas.style.width = `${width.value / (window.devicePixelRatio || 1)}px`;
  canvas.style.height = `${width.value / (window.devicePixelRatio || 1)}px`;
  ctx.translate(width.value / 2, width.value / 2);
  ctx.rotate(-Math.PI / 2);
  draw();
}

function draw() {
  ctx.font = Math.min(18, width.value / 10) + "px sans-serif";
  const radius = width.value * 0.375;
  ctx.clearRect(
    -width.value / 2,
    -width.value / 2,
    width.value + 1,
    width.value + 1,
  );

  ctx.save();
  const data = props.value.filter((d) => d.value > 0);
  const select = data.findIndex((d) => d.name === props.select);
  const len = data.length;
  if (len === 0) {
    ctx.fillStyle = ctx.createPattern(emptyPattern, "repeat")!;
    drawCircle(radius);
    ctx.fillStyle = color.back;
    drawCircle(holeSize * radius);
  } else {
    let a = 0;
    for (let i = 0; i < len; i++) {
      const value = data[i].value / props.total;
      const e = a + value * 2 * Math.PI;
      ctx.fillStyle = toCssColor(data[i].color);
      drawArc(a, e, radius, markPos(i, select, len));
      if (value > 0.05) {
        drawText(value, data[i].color, (a + e) / 2, radius, i === select);
      }
      a = e;
    }
  }
  ctx.restore();
}

function markPos(i: number, select: number, len: number): Mark {
  const pos = i - select;
  if (select < 0) {
    return "none";
  }
  if (pos === 0) {
    return len === 1 ? "single" : "this";
  }
  if (pos === len - 1) {
    return "before";
  }
  if (pos === -(len - 1)) {
    return "after";
  }
  return "none";
}

function drawText(
  value: number,
  color: Color,
  angle: number,
  radius: number,
  mark: boolean,
) {
  ctx.save();
  ctx.fillStyle = contrastCssColor(color);
  ctx.rotate(Math.PI / 2);

  let zoomFactor = 1.5;
  if (props.zoom && props.zoom < 4) {
    zoomFactor = 4;
  }
  const fontSize =
    Math.min(16, width.value / 10) * zoomFactor * (props.zoom || 1);
  ctx.font = `${fontSize}px sans-serif`;
  const text = Math.round(100 * value) + "%";
  const size = ctx.measureText(text);
  const height = size.actualBoundingBoxAscent - size.actualBoundingBoxDescent;
  const c = dir(angle, 1);
  ctx.fillText(
    text,
    (radius * 0.9 - size.width / 2 + (mark ? markDist * radius : 0)) * c.y -
      size.width / 2,
    -(radius * 0.9 - height / 2 + (mark ? markDist * radius : 0)) * c.x +
      height / 2,
  );
  ctx.restore();
}

function dir(angle: number, r: number) {
  return { x: r * Math.cos(angle), y: r * Math.sin(angle) };
}

function drawArc(from: number, to: number, radius: number, mark: Mark) {
  ctx.save();
  ctx.beginPath();
  const c = dir((from + to) / 2, mark === "this" ? markDist * radius : 0);
  if (!props.hole) {
    ctx.moveTo(c.x, c.y);
    ctx.arc(c.x, c.y, radius, from, to, false);
    ctx.lineTo(c.x, c.y);
    ctx.stroke();
  } else {
    if (mark !== "this") {
      // clip shadow
      const s = new Path2D();
      const f = from - (mark === "after" ? 0.4 : 0);
      const t = to + (mark === "before" ? 0.4 : 0);
      s.arc(c.x, c.y, 2 * radius, f, t, false);
      s.arc(c.x, c.y, 0, t, f, true);
      ctx.clip(s);
    }
    ctx.arc(
      c.x,
      c.y,
      radius * (mark === "single" ? 1 + markDist : 1),
      from,
      to,
      false,
    );
    ctx.arc(c.x, c.y, holeSize * radius, to, from, true);
  }
  ctx.fill();
  ctx.restore();
}

function drawCircle(radius: number) {
  ctx.beginPath();
  ctx.arc(0, 0, radius, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
}
</script>

<template>
  <canvas ref="canvasRef" class="pie-chart" data-no-screenshot />
</template>

<style lang="scss">
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>
