<script setup lang="ts">
import UserAvatar from "@/components/UserAvatar.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { AuthUser } from "@/model/user";

defineProps<{
  header?: string;
  modelValue?: AuthUser | AuthUser[];
  values: AuthUser[];
  scrollable?: boolean;
}>();

const emit = defineEmits<{
  "update:model-value": [value: AuthUser | AuthUser[]];
}>();
</script>

<template>
  <SelectableList
    v-keyboard-nav
    :header="header"
    :model-value="modelValue"
    :values="values"
    :scrollable="scrollable"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #default="{ value }">
      <span>
        <UserAvatar :user="value" />
        <span style="max-height: 2.5em; overflow: hidden">
          {{ value.name }}
        </span>
      </span>
    </template>
  </SelectableList>
</template>
