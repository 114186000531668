import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import {
  Rectangle,
  RelativeCoordinate,
  rectangle,
  relativeCoord,
} from "@/model/coordinates";
import { Shape } from "@/model/shape";

export interface BoardLocation {
  name: string;
  bounds: Rectangle<RelativeCoordinate>;
  shapes: Shape[];

  matches(coord: RelativeCoordinate): boolean;

  index(): number[];
}

export const fullBoardLocation: BoardLocation = {
  name: "",
  bounds: rectangle(relativeCoord(0, 0), relativeCoord(1, 1)),
  shapes: [],
  matches() {
    return true;
  },
  index() {
    return [];
  },
};

export function cardsInLocation(board: Board, loc: BoardLocation): BoardCard[] {
  const cards: BoardCard[] = [];
  for (const id in board.cards) {
    if (loc.matches(board.cards[id].meta.pos)) {
      cards.push(board.cards[id]);
    }
  }
  return cards;
}
