import { i18n } from "@/i18n";
import CardFlag, { FlagColor, FlagIcon } from "@/model/CardFlag";
import { StickersIcon } from "@/types/icon";

export const iconMap: Record<FlagIcon, StickersIcon> = {
  none: "stickers/none" /* $t("label.icon.stickers/none") */,
  flag: "stickers/flag" /* $t("label.icon.stickers/flag") */,
  check: "stickers/checkmark" /* $t("label.icon.stickers/checkmark") */,
  round: "stickers/dot" /* $t("label.icon.stickers/dot") */,
  star: "stickers/star" /* $t("label.icon.stickers/star") */,
  question:
    "stickers/question-mark" /* $t("label.icon.stickers/question-mark") */,
  exclam:
    "stickers/exclamation-mark" /* $t("label.icon.stickers/exclamation-mark") */,
};

export const colorMap: Record<number, FlagColor> = {
  0: "red" /* $t("label.color.red") */,
  1: "orange" /* $t("label.color.orange") */,
  2: "yellow" /* $t("label.color.yellow") */,
  3: "blue" /* $t("label.color.blue") */,
  4: "purple" /* $t("label.color.purple") */,
  5: "green" /* $t("label.color.green") */,
};

/**
 * Returns the name of the flag (color + type)
 */
export const flagName = (flag: CardFlag): string => {
  const t = iconMap[flag.icon];
  const type = i18n.global.t(`label.icon.${t}`);

  const c = colorMap[flag.colorIndex];
  const color = flag.icon !== "none" ? i18n.global.t(`label.color.${c}`) : "";

  return i18n.global.t("label.stickyNote.sticker", { type, color });
};
