<script lang="ts" setup>
import { computed } from "vue";

import { cardKey } from "@/components/card/injectKeys";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";
import UserReactions from "./components/UserReactions/UserReactions.vue";

const card = injectStrict(cardKey);

const isActive = computed(() => card.id === useBoardStore().activeCardId);
</script>

<template>
  <StickyNoteRow class="note-footer">
    <UserReactions />
    <StickyLinksChip v-if="isActive" />
  </StickyNoteRow>
</template>
